









































import { InputSetups } from '@/mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Mixins, Prop, PropSync, VModel } from 'vue-property-decorator'
import moment from 'moment'
import TimezonePicker from 'piramis-base-components/src/components/TimezonePicker/TimezonePicker.vue'
import { SetTimezonePayload } from "piramis-base-components/src/components/TimezonePicker/types";

@Component({
  components: {
    TimezonePicker
  },
  methods: {
    moment,
  }
})
export default class PostTime extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel() runTime!: string

  @PropSync('timezone') tz!: string

  @Prop({ type: Boolean, default: false }) disabled !: boolean

  setTimezone(payload: SetTimezonePayload) {
    this.tz = payload.timezone

    payload.closeModal()
    payload.closeLoader()
  }

  get postDate():string {
    return this.runTime.split(' ')[0] ?? ''
  }

  set postDate(newDate:string) {
    this.updateDatetime(newDate, undefined)
  }

  get postTime():string {
    return this.runTime.split(' ')[1] ?? ''
  }

  set postTime(newTime:string) {
    this.updateDatetime(undefined, newTime)
  }

  updateDatetime(date = this.postDate, time = this.postTime):void {
    this.runTime = `${ date } ${ time }`
  }

  initPostDate() {
    const postDate = this.$route.query?.date?.toString()

    if (postDate) {
      this.postDate = postDate
    } else {
      const splitRunTime = this.runTime.split(' ')

      this.postDate = splitRunTime[0]
    }
  }

  initPostTime() {
    const postTime = this.$route.query?.time?.toString()

    if (postTime) {
      this.postTime = postTime
    } else {
      const splitRunTime = this.runTime.split(' ')

      this.postTime = splitRunTime[1]
    }
  }

  mounted(): void {
    this.initPostDate()
    this.initPostTime()
  }
}
