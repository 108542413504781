var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"post-time"},[_c('div',{staticClass:"date-pickers__wrapper flex flex-col xl:flex-row xl:gap-2"},[_c('date-picker-input',{staticClass:"date-pickers__date w-full mt-0 mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'validation': 'required',
          'model': this,
          'key': 'postDate',
          'clearable': false,
          'disabled': _vm.disabled,
          'minDate': 'today',
        }
      }}}),_c('time-picker-input',{staticClass:"date-pickers__time w-full mt-0 mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'validation': 'required',
          'model': this,
          'key': 'postTime',
          'time24hr': true,
          'clearable': false,
          'disabled': _vm.disabled
        },
      }}})],1),_c('timezone-picker',{attrs:{"timezone":_vm.tz,"prefix":"post_"},on:{"set-timezone":_vm.setTimezone}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }